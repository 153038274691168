import React from "react";
import onWaySVG from "assets/img/svgs/logs-icons/shipment-status-onway.svg";
import returnedSVG from "assets/img/svgs/logs-icons/shipment-status-returend.svg";
import deliveredSVG from "assets/img/svgs/logs-icons/shipment-status-check.svg";
// import awaitingPackagingSVG from 'assets/img/svgs/logs-icons/shipment-status-plus.svg';
import rejectedSVG from "assets/img/svgs/logs-icons/shipment-status-canceled.svg";
import calendarSVG from "assets/img/svgs/logs-icons/calendar.svg";
import hourglassSVG from "assets/img/svgs/logs-icons/hourglass 2.svg";
import outgoingCallSVG from "assets/img/svgs/logs-icons/outgoing-call.svg";
import incomingCallSVG from "assets/img/svgs/logs-icons/incoming-call.svg";
import locationSVG from "assets/img/svgs/logs-icons/location.svg";
import airplaneSVG from "assets/img/svgs/logs-icons/airplane.svg";
import packedSVG from "assets/img/svgs/logs-icons/shipment-status-packed.svg";
import pickedUpSVG from "assets/img/svgs/logs-icons/shipment-status-hand.svg";
import warehouseSVG from "assets/img/svgs/logs-icons/shipment-status-warehouse.svg";
import problemSVG from "assets/img/svgs/logs-icons/problem.svg";
import orderAwaitingPackagingSVG from "assets/img/svgs/logs-icons/shipment-status-awaiting-packaging.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "gatsby-plugin-react-i18next";
import productsArrivedSVG from "assets/img/svgs/logs-icons/protection.svg";

export const Get_shipment_status_template = (log) => {
  const { t } = useTranslation("order-details-logs");
  log.details = log.details ? JSON.parse(log.details) : null;
  switch (log.status) {
    case "awaitingpackaging":
      return {
        icon: orderAwaitingPackagingSVG,
        title: t("Shipment is awaiting to be fulfilled"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "packed":
      return {
        icon: packedSVG,
        title: t("Shipment has been prepared and is ready to be shipped"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "pickedup":
      return {
        icon: pickedUpSVG,
        title: t("Shipment has been picked up by the carrier"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "at_carrier_origin_facility":
      return {
        icon: locationSVG,
        title: t("Shipment has arrived at the carrier origin facility"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "in_transit":
      return {
        icon: airplaneSVG,
        title: t("Shipment is in transit to the destination facility"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "at_carrier_destination_facility":
      return {
        icon: locationSVG,
        title: t("Shipment has arrived at carrier destination facility"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "at_carrier_facility":
      return {
        icon: locationSVG,
        title: t("Shipment is at the carrier facility"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "onitsway":
      return {
        icon: onWaySVG,
        title: t("Shipment is out for delivery"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "delivery_issue":
      return {
        icon: problemSVG,
        title: t("There was an issue with the delivery of shipment"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "shipment_rejected_by_consignee":
      return {
        icon: rejectedSVG,
        title: t("Shipment has been rejected by the customer"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "on_hold":
      return {
        icon: hourglassSVG,
        title: t("Shipment is on hold"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "delivery_rescheduled":
      return {
        icon: calendarSVG,
        title: t("Delivery rescheduled for shipment"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "delivery_agent_initiated_call":
      return {
        icon: outgoingCallSVG,
        title: t("Delivery agent initiated a call for shipment"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "consignee_initiated_call":
      return {
        icon: incomingCallSVG,
        title: t("Customer initiated a call for shipment"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "returnedtocarrierwarehouse":
      return {
        icon: warehouseSVG,
        title: t("Shipment has been returned to the carrier facility."),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "return_to_sender_decided":
      return {
        icon: returnedSVG,
        title: t("Decision has been made to return shipment to the sender."),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "returning_to_sender":
      return {
        icon: returnedSVG,
        title: t("Shipment is returning to the sender."),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "returnedtosender":
      return {
        icon: returnedSVG,
        title: t("Shipment has been returned to the sender"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "awaiting_pickup_by_consignee":
      return {
        icon: locationSVG,
        title: t("Shipment is awaiting pickup by the customer"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "delivered":
      return {
        icon: deliveredSVG,
        title: t("Shipment has been delivered to the customer"),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "returnatrecipientbranch":
      return {
        icon: warehouseSVG,
        title: t("Returned shipment is at the carrier destination facility."),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "returnatsenderbranch":
      return {
        icon: warehouseSVG,
        title: t("Returned shipment is at the carrier origin facility."),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "returnreceived":
      return {
        icon: returnedSVG,
        title: t("Returned shipment has been returned to the sender."),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "confirmed_return_reception":
      return {
        icon: returnedSVG,
        title: t(
          "The sender has confirmed the receipt of the returned shipment."
        ),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
      };
    case "shipment_stock_resolved":
      return {
        title: t(
          "The required products for shipment has arrived to our fulfillment center and passed quality control"
        ),
        description: log.details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(log.details.comment)}
            </span>
          </>
        ),
        icon: productsArrivedSVG,
      };
    default:
      return null;
  }
};
