import React from "react";
import { getAmountWithCurrencySymbol } from "helpers/FormatCurrency";
import barcodeSVG from "assets/img/svgs/logs-icons/barcode.svg";
import safeSVG from "assets/img/svgs/logs-icons/safety (1).svg";
// import shippingLabelSVG from 'assets/img/svgs/logs-icons/email (1).svg';
import orderCreatedSVG from "assets/img/svgs/logs-icons/shipment-status-plus.svg";
import canceledSVG from "assets/img/svgs/logs-icons/shipment-status-canceled.svg";
import productsTransferSVG from "assets/img/svgs/logs-icons/trolley (1).svg";
import productsArrivedSVG from "assets/img/svgs/logs-icons/protection.svg";
import ticketSVG from "assets/img/svgs/logs-icons/tag (1).svg";
import awaitingConfirmationSVG from "assets/img/svgs/logs-icons/hourglass.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "gatsby-plugin-react-i18next";

export const Get_action_template = (log) => {
  const { t } = useTranslation("order-details-logs");
  const details = log.details ? JSON.parse(log.details) : null;
  switch (log.action) {
    case "order-passed-with-confirmation-service":
      return {
        title: t("Order awaiting confirmation"),
        description: t(
          "Once the customer confirms the order, it will be passed to the fulfillment center."
        ),
        icon: awaitingConfirmationSVG,
      };
    case "generate-shipment-tracking-number":
      return {
        title: t(
          "Shipment has been packed, tracking number {{tracking_number}}",
          {
            tracking_number: log.tracking_number.shipment_tracking_number,
            // shipment_id: log.shipment_id,
          }
        ),
        description: details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(details.comment)}
            </span>
          </>
        ),
        icon: barcodeSVG,
      };
    case "shipment-price-update":
      return {
        title: t("Shipment COD amount updated"),
        description: t(
          "Shipment of tracking number {{tracking_number}} COD amount has been updated from {{old_amount}} to {{new_amount}}",
          {
            tracking_number: log.tracking_number.shipment_tracking_number,
            shipment_id: log.shipment_id,
            old_amount: getAmountWithCurrencySymbol(
              details.old_amount,
              log.currency
            ),
            new_amount: getAmountWithCurrencySymbol(
              details.new_amount,
              log.currency
            ),
          }
        ),
        icon: safeSVG,
      };
    case "shipment-profit-update":
      return {
        title: t("Shipment revenue amount has been corrected"),
        description: t(
          "Shipment revenue amount has been corrected from {{old_amount}} to {{new_amount}}",
          {
            shipment_id: log.shipment_id,
            old_amount: getAmountWithCurrencySymbol(
              details.old_amount,
              log.currency
            ),
            new_amount: getAmountWithCurrencySymbol(
              details.new_amount,
              log.currency
            ),
          }
        ),
        icon: safeSVG,
      };
    case "shipment-fees-payment-update":
      return {
        title: t("Shipment fees payment amount has been corrected"),
        description: t(
          "Shipment fees payment amount has been corrected from {{old_amount}} to {{new_amount}}",
          {
            shipment_id: log.shipment_id,
            old_amount: getAmountWithCurrencySymbol(
              details.old_amount,
              log.currency
            ),
            new_amount: getAmountWithCurrencySymbol(
              details.new_amount,
              log.currency
            ),
          }
        ),
        icon: safeSVG,
      };
    case "confirmation-agent-updated-shipment-price":
      return {
        title: t("Shipment COD amount updated"),
        description: (
          <>
            <div>
              {t(
                "Shipment COD amount has been updated from {{old_amount}} to {{new_amount}}",
                {
                  shipment_id: log.shipment_id,
                  old_amount: getAmountWithCurrencySymbol(
                    details.old_amount,
                    log.currency
                  ),
                  new_amount: getAmountWithCurrencySymbol(
                    details.new_amount,
                    log.currency
                  ),
                }
              )}
            </div>
            <div className="text-800">
              <span className="fw-semi-bold text-info">{t("Note")}</span>:{" "}
              {details.edit_reason}
            </div>
          </>
        ),
        icon: safeSVG,
      };
    case "shipment_is_fulfillable":
      return {
        title: t("Shipment has been created and is ready to be processed", {
          shipment_id: log.shipment_id,
        }),
        description: details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(details.comment)}
            </span>
          </>
        ),

        icon: orderCreatedSVG,
      };
    case "shipment_is_awaiting_stock_confirmation":
      return {
        title: t(
          "Transferring shipment products from supplier warehouse to our fulfillment center for quality check and packing",
          {
            shipment_id: log.shipment_id,
          }
        ),
        description: details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(details.comment)}
            </span>
          </>
        ),
        icon: productsTransferSVG,
      };
    case "shipment_stock_resolved":
      return {
        title: t(
          "The required products for shipment has arrived to our fulfillment center and passed quality control",
          {
            shipment_id: log.shipment_id,
          }
        ),
        description: details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(details.comment)}
            </span>
          </>
        ),
        icon: productsArrivedSVG,
      };

    case "shipment_canceled":
      return {
        icon: canceledSVG,
        title: t("Shipment has been canceled", {
          shipment_id: log.shipment_id,
        }),
        description: details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(details.comment)}
            </span>
          </>
        ),
      };
    case "shipment_rejected":
      return {
        icon: canceledSVG,
        title: t("Shipment has been rejected by the supplier.", {
          shipment_id: log.shipment_id,
        }),
        description: details?.comment && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(details.comment)}
            </span>
          </>
        ),
      };
    case "create-carrier-ticket":
      return {
        icon: ticketSVG,
        title: t("A ticket has been issued to the carrier for shipment", {
          shipment_id: log.shipment_id,
        }),
        description: details?.title && (
          <>
            <span>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {t(details.title)}
            </span>
          </>
        ),
      };
    case "mark-shipment-as-ready-for-pickup":
      return {
        icon: productsTransferSVG,
        title: t(
          "Shipment has been marked by the supplier as ready for pickup",
          {
            shipment_id: log.shipment_id,
          }
        ),
      };
    case "Generate shipping label attempt":
      return null;
    default:
      return null;
  }
};
