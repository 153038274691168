import { Get_action_template } from "./get_action_template";
import { Get_shipment_status_template } from "./get_shipment_status_template";

export const get_formatted_log = (log, t) => {
  switch (log.action) {
    case "shipment-status-update-feedback":
      return Get_shipment_status_template(log, t);
    case "service-user-update-shipment-status":
      return Get_shipment_status_template(log, t);
    default:
      return Get_action_template(log, t);
  }
};
