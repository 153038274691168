import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const EnterTrackingInfoPage = ({ t, setShipment_uuid }) => {
  const [trackingNumber, setTrackingNumber] = useState("");
  return (
    <Col className="mb-2">
      <h5 className="py-4">
        {t(
          "Easily track your shipments and stay informed about their delivery status."
        )}
      </h5>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>{t("Shipment Number")}</Form.Label>
        <Row>
          <Col sm={7} md={8}>
            <Form.Control
              type="email"
              className="my-2"
              placeholder={t("Eg: {{tn}}", {
                tn: "ab0ec9b0-878a-11ed-afcf-8905231e11e7",
              })}
              onChange={(e) => setTrackingNumber(e.target.value)}
              value={trackingNumber}
            />
            {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
          </Col>
          <Col sm={5} md={4}>
            <Button
              variant="primary"
              className="bg-card-gradient border-0 my-2 w-100 text-nowrap"
              onClick={() => {
                setShipment_uuid(trackingNumber);
                window.history.pushState({}, "", `?id=${trackingNumber}`);
              }}
            >
              {t("Track my shipment")}
            </Button>
          </Col>
        </Row>
      </Form.Group>
    </Col>
  );
};

export default EnterTrackingInfoPage;
