import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import WidgetSectionTitle from "widgets/WidgetSectionTitle";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Accordion } from "react-bootstrap";
import { faList } from "@fortawesome/free-solid-svg-icons";

dayjs.extend(utc);
dayjs.extend(tz);

const timeZone = dayjs.tz.guess();

export default function Logs({
  sorted_formatted_logs,
  title,
  subtitle,
  use_accordion = false,
}) {
  const isRtl = document.body.style.direction === "rtl";
  return (
    <div className="mt-3">
      {use_accordion && (
        <Accordion defaultActiveKey="0" className="p-0 border-0">
          <Accordion.Item eventKey="0" className="cursor-pointer ">
            <Accordion.Button as="div" className="px-3 text-primary">
              <WidgetSectionTitle
                icon={faList}
                title={title}
                // subtitle={subtitle}
                transform="shrink-2"
                className="fs--1 d-flex align-items-center text-primary w-100"
              />
            </Accordion.Button>
            <Accordion.Body className="bg-100 p-2">
              <VerticalTimeline
                lineColor="#317c61"
                layout={isRtl ? "1-column-right" : "1-column-left"}
              >
                {sorted_formatted_logs.map((log, index) => (
                  <Log
                    key={index}
                    log={log}
                    isLast={index === sorted_formatted_logs.length - 1}
                    index={index}
                  />
                ))}
              </VerticalTimeline>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </div>
  );
}

function Log({
  log: { title, description, icon, created_at, completed = true },
  index,
}) {
  const isRtl = document.body.style.direction === "rtl";
  return (
    <VerticalTimelineElement
      key={index}
      className="vertical-timeline-element--work my-3"
      contentStyle={
        index === 0
          ? {
              background: "rgb(48 124 97)",
              color: "#fff",
              textAlign: isRtl ? "right" : "left",
            }
          : {
              background: "rgb(99 171 121)",
              color: "#fff",
              textAlign: isRtl ? "right" : "left",
            }
      }
      // contentArrowStyle={{
      //   borderRight: isRtl ? 'none' : `7px solid ${event.iconBackground}`,
      //   borderLeft: isRtl ? `7px solid ${event.iconBackground}` : 'none'
      // }}
      contentArrowStyle={
        index === 0
          ? {
              borderRight: isRtl ? "none" : "7px solid rgb(48 124 97)",
              borderLeft: isRtl ? "7px solid  rgb(48 124 97)" : "none",
            }
          : {
              borderRight: isRtl ? "none" : "7px solid rgb(99 171 121)",
              borderLeft: isRtl ? "7px solid  rgb(99 171 121)" : "none",
            }
      }
      date={
        !created_at ? null : (
          <>{dayjs.utc(created_at).tz(timeZone).format("HH:mm - DD MMM YY")}</>
        )
      }
      dateClassName="pb-0"
      iconStyle={{ background: "#fff", color: "#fff" }}
      icon={
        <img src={icon} alt={title} style={{ width: "100%", height: "100%" }} />
      }
    >
      <h4 className="vertical-timeline-element-title mb-0">{title}</h4>
      <p className="mt-2">{description}</p>
    </VerticalTimelineElement>
  );
}

Logs.propTypes = {
  sorted_formatted_logs: PropTypes.array.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  use_accordion: PropTypes.bool,
};

Log.propTypes = {
  log: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
