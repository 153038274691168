import React from "react"

const LogoIcon = ({ isSpining, height = "40px" }) => {
  return (
    <svg
      style={{
        height: height,
        filter: "brightness(0.6)",
      }}
      viewBox="0 0 65 65"
      className={isSpining && "logo-icon-spin-animation"}
    >
      <g
        id="SvgjsG2215"
        featurekey="symbolFeature-0"
        transform="matrix(0.7363646578211807,0,0,0.7363646578211807,-4.418130713384057,-4.418187946927084)"
        fill="#4ecca3"
      >
        <g
          xmlns="http://www.w3.org/2000/svg"
          transform="translate(0,-952.36218)"
        >
          <path
            style={{
              textIndent: 0,
              textTransform: "none",
              direction: "ltr",
              blockProgression: "tb",
              baselineShift: "baseline",
              color: "",
              enableBackground: "accumulate",
            }}
            d="m 13.540789,1013.168 c -4.1612604,0 -7.5408665,3.3922 -7.5408665,7.5693 0,4.1771 3.3796061,7.605 7.5408665,7.605 0.813543,0 1.613976,-0.1361 2.383228,-0.3928 12.281102,18.8997 36.649842,23.2608 54.493227,13.032 0.521221,-0.2991 0.724607,-1.0475 0.426614,-1.571 -0.297992,-0.5234 -1.043503,-0.7275 -1.565078,-0.4284 -16.772953,9.6153 -39.67122,5.6292 -51.327282,-12.1037 1.894251,-1.3812 3.130157,-3.6195 3.130157,-6.1411 0,-4.1771 -3.379252,-7.5693 -7.540866,-7.5693 z"
            fill="#4ecca3"
            fillOpacity={1}
            fillRule="evenodd"
            stroke="none"
            marker="none"
            visibility="visible"
            display="inline"
            overflow="visible"
          />
          <path
            style={{
              textIndent: 0,
              textTransform: "none",
              direction: "ltr",
              blockProgression: "tb",
              baselineShift: "baseline",
              color: "",
              enableBackground: "accumulate",
            }}
            d="m 70.417244,970.57299 c -0.951023,0.12132 -1.237323,1.69026 -0.391181,2.14225 13.429842,8.21899 20.928543,24.30182 17.64248,40.55986 -0.392953,-0.067 -0.80185,-0.107 -1.209331,-0.107 -4.161259,0 -7.540866,3.3922 -7.540866,7.5692 0,4.1771 3.379607,7.605 7.540866,7.605 4.16126,0 7.540866,-3.4279 7.540866,-7.605 0,-2.9516 -1.686968,-5.51 -4.161614,-6.748 3.607441,-17.29107 -4.331338,-34.48188 -18.638503,-43.23773 -0.189921,-0.12122 -0.415984,-0.18423 -0.64063,-0.17852 -0.04784,-0.003 -0.09425,-0.003 -0.142087,0 z"
            fill="#4ecca3"
            fillOpacity={1}
            fillRule="evenodd"
            stroke="none"
            marker="none"
            visibility="visible"
            display="inline"
            overflow="visible"
          />
          <path
            style={{
              textIndent: 0,
              textTransform: "none",
              direction: "ltr",
              blockProgression: "tb",
              baselineShift: "baseline",
              color: "",
              enableBackground: "accumulate",
            }}
            d="m 50.000001,958.36218 c -4.012441,0 -7.27441,3.16987 -7.505079,7.14083 -17.197086,3.19362 -29.727637,16.85266 -32.5821254,33.06201 a 1.1383515,1.1426463 0 1 0 2.2407874,0.39275 c 2.681221,-15.22486 14.388307,-28.07084 30.518858,-31.1697 0.826653,3.28539 3.802677,5.71266 7.327559,5.71266 4.161259,0 7.540866,-3.39219 7.540866,-7.56928 0,-4.17708 -3.379607,-7.56927 -7.540866,-7.56927 z"
            fill="#4ecca3"
            fillOpacity={1}
            fillRule="evenodd"
            stroke="none"
            marker="none"
            visibility="visible"
            display="inline"
            overflow="visible"
          />
        </g>
      </g>
    </svg>
  )
}

export default LogoIcon
