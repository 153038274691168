import React from 'react';
import dayjs from 'dayjs';

import busySVG from 'assets/img/svgs/contact-logs-icons/call-back.svg';
import wrongNumberSVG from 'assets/img/svgs/contact-logs-icons/warning.svg';
import unreachableSVG from 'assets/img/svgs/contact-logs-icons/blocked (1).svg';
import confirmedSVG from 'assets/img/svgs/contact-logs-icons/check (2).svg';
import disqualifiedSVG from 'assets/img/svgs/contact-logs-icons/close.svg';

export const get_customer_outreach_formatted_log = (customer_outreach, t) => {
  const confirmation_user_name = customer_outreach.by.first_name;
  switch (customer_outreach.status) {
    case 'unreachable':
      return {
        icon: unreachableSVG,
        title: t('Customer unreachable'),
        description: t(
          '{{confirmation_user_name}} tried to call but customer was unreachable',
          {
            confirmation_user_name
          }
        )
      };
    case 'busy':
      return {
        icon: busySVG,
        title: t('Customer busy'),
        description: t(
          '{{confirmation_user_name}} tried to call but customer was busy',
          {
            confirmation_user_name
          }
        )
      };
    case 'called':
      return {
        icon: confirmedSVG,
        title: t('Customer confirmed order'),
        description: (
          <>
            {t(
              '{{confirmation_user_name}} has called customer and confirmed order successfully',
              { confirmation_user_name }
            )}
            {customer_outreach.call_feedback && (
              <div className="text-800">
                <span className="fw-semi-bold text-info">{t('Note')}</span>:{' '}
                {customer_outreach.call_feedback}
              </div>
            )}
          </>
        )
      };
    case 'other':
      return {
        icon: confirmedSVG,
        title: t('Customer called'),
        description: (
          <>
            {t(
              '{{confirmation_user_name}} has called customer and updated confirmation status to (Other)',
              { confirmation_user_name }
            )}
            {customer_outreach.call_feedback && (
              <div className="text-800">
                <span className="fw-semi-bold text-info">{t('Note')}</span>:{' '}
                {customer_outreach.call_feedback}
              </div>
            )}
          </>
        )
      };
    case 'resolved':
      return {
        icon: confirmedSVG,
        title: t('Customer has been called'),
        description: (
          <>
            {customer_outreach.delivery_rescheduled_to ? (
              <div>
                {t(
                  '{{confirmation_user_name}} has called the customer and rescheduled delivery to',
                  { confirmation_user_name }
                ) +
                  ' ' +
                  dayjs(customer_outreach.delivery_rescheduled_to).format(
                    'DD MMM HH:mm'
                  )}
              </div>
            ) : (
              <div>
                {t(
                  '{{confirmation_user_name}} has called the customer to resolve delivery issue',
                  { confirmation_user_name }
                )}
              </div>
            )}
            {customer_outreach.call_feedback && (
              <div className="text-800">
                <span className="fw-semi-bold text-info">{t('Note')}</span>:{' '}
                {customer_outreach.call_feedback}
              </div>
            )}
          </>
        )
      };
    case 'wrong-number':
      return {
        icon: wrongNumberSVG,
        title: t('Wrong number'),
        description: t('{{name}} tried to call but the number was wrong', {
          name: confirmation_user_name
        })
      };
    case 'failed':
      return {
        icon: disqualifiedSVG,
        title: t('Customer declined order'),
        description: (
          <>
            {t(
              '{{confirmation_user_name}} has called the customer but they declined the order',
              { confirmation_user_name }
            )}
            {customer_outreach.call_feedback && (
              <div className="text-800">
                <span className="fw-semi-bold text-info">{t('Note')}</span>:{' '}
                {customer_outreach.call_feedback}
              </div>
            )}
          </>
        )
      };
    case 'disqualified':
      return {
        icon: disqualifiedSVG,
        title: t('Lead disqualified'),
        description: (
          <>
            {t(
              '{{confirmation_user_name}} has called the customer but they declined the order',
              { confirmation_user_name }
            )}
            {customer_outreach.call_feedback && (
              <div className="text-800">
                <span className="fw-semi-bold text-info">{t('Note')}</span>:{' '}
                {customer_outreach.call_feedback}
              </div>
            )}
          </>
        )
      };
    default:
      return null;
  }
};
