// import { graphqlClient } from 'graphql/graphql-client';
// import { GetExchangeRates } from 'graphql/currencies/GetExchangeRates';

export function getNumberFromFormatedAmount(formatedAmount, currency) {
  return Number(formatedAmount.split(currency.ISO_4217)[0].replace(/ /g, ""));
}

export function getAmountInCurrencyDecimals(amount, currency) {
  return Number(Number(amount ? amount : 0).toFixed(currency.decimals));
}

export function getAmountWithCurrencySymbol(
  amount,
  currency,
  type = "ISO_4217",
  hide = false
) {
  if (hide) {
    return `***** ${
      type === "ISO_4217" ? currency?.ISO_4217 : currency?.symbol
    }`;
  }
  return `${Number(amount ? amount : 0).toFixed(currency?.decimals)} ${
    type === "ISO_4217" ? currency?.ISO_4217 : currency?.symbol
  }`;
}

export function getFirstPartOfUUID(uuid) {
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

  if (uuidRegex.test(uuid)) {
    const firstPart = uuid.split("-")[0];
    return firstPart;
  } else {
    return uuid;
  }
}

// export async function getConversionRatios({ target }) {
//   let res = await graphqlClient.request(GetExchangeRates, { target });
//   return JSON.parse(res.GetExchangeRates);
// }

export function getConvertedWalletsFromConversionRatios({
  wallets,
  conversion_ratios,
}) {
  return wallets.map((wallet) => ({
    ...wallet,
    wallet_currencies: wallet.wallet_currencies.map((wallet_currencie) => {
      const rate = conversion_ratios.find(
        (ratio) => ratio.source === wallet_currencie.currency.ISO_4217
      ).rate;
      const amount_converted_to_display_currency =
        Number(wallet_currencie.amount) !== 0
          ? Number(wallet_currencie.amount) * rate
          : 0;
      return {
        ...wallet_currencie,
        amount: amount_converted_to_display_currency,
      };
    }),
  }));
}

export function reduceCurrencies(values, initial_value = []) {
  // array of {amout,currency}
  return values.reduce((total, item) => {
    let item_currency_total = total.find(
      (total_currency) => total_currency.currency.id === item.currency.id
    );
    if (item_currency_total) {
      return total.map((total_currency) => {
        if (total_currency.currency.id === item.currency.id) {
          return {
            ...total_currency,
            amount: Number(total_currency.amount) + Number(item.amount),
          };
        }
        return total_currency;
      });
    } else {
      return [...total, { ...item, amount: Number(item.amount) }];
    }
  }, initial_value);
}
